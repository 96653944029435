<template>
  <div>
    <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Add teaser Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog fullscreen v-model="dialog" :retain-focus="false">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Add new Video</span>
          <v-spacer></v-spacer>
          <v-btn light @click="dialog = false">Cancel</v-btn>
          <v-btn
              class="ml-3"
              light
              :disabled="isAllowAddImage()"
              :loading="waitAddNewImage"
              @click="onAddImage">
            Add
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form v-model="formAddImage" ref="formAddImage">
            <v-container fluid>

              <v-row justify="center"
                     v-if="$store.getters['user/getUserName'] === 'denis' ||$store.getters['user/getUserName'] === 'miro'">
                <v-col cols="12" sm="4" align-self="center">
                  <v-radio-group
                      v-model="imageDialogFormat"
                      row
                  >
                    <v-radio
                        label="Old"
                        value="old"
                    ></v-radio>
                    <v-radio
                        label="With aspect ratio"
                        value="withAspectRatio"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12">
<!--                  <v-text-field-->
<!--                      v-if="!isDisableDownloadImageFromUrl()"-->
<!--                      v-model="downloadImageFromUrl"-->
<!--                      :rules="[checkDownloadImage]"-->
<!--                      label="Download video from URL"-->
<!--                      clearable-->
<!--                      @click:clear="resetForm"-->
<!--                  ></v-text-field>-->
                  <v-file-input
                      v-if="!isDisableUploadImage()"
                      v-model="uploadImageFile"
                      label="Upload video"
                      :truncate-length="100"
                      :rules="[checkUploadImage]"
                      accept="video/mp4"
                      @change="selectedUploadImage"
                      @click:clear="resetForm"
                      clearable
                  ></v-file-input>
                  <p v-if="originImage.loaded">
                    <b>{{ originImage.width }} x {{ originImage.height }}</b> ( width X height ) <br>
                    <b>{{ originImage.size }}</b> bytes<br>
                    <!--                  <b>{{ originImage.aspectRatio }}</b> aspect ration-->
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4">
                  <v-combobox label="Tag" v-model="tag1" :items="tags"></v-combobox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-combobox label="Tag" v-model="tag2" :items="tags"></v-combobox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-combobox label="Tag" v-model="tag3" :items="tags"></v-combobox>
                </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="imageDialogFormat === 'old'">
                <v-col align="center" justify="center" class="pa-0 ma-0">
                  <div
                      v-if="originImage.loaded && !isDisableDownloadImageFromUrl() && downloadImageFromUrl !== null && downloadImageFromUrl.length>0"
                      :style="{width:originImage.width, height: originImage.height}">
                    <v-img
                        :style="{border: '1px solid rgb(0 0 0 / 11%)'}"
                        :width="originImage.width"
                        :height="originImage.height"
                        :lazy-src="downloadImageFromUrl"
                        :src="downloadImageFromUrl"
                    />
                  </div>

                  <div
                      v-if="originImage.loaded && !isDisableUploadImage()"
                      :style="{width:originImage.width, height: originImage.height}">
                    <v-img
                        :style="{border: '1px solid rgb(0 0 0 / 11%)'}"
                        :width="originImage.width"
                        :height="originImage.height"
                        :src="uploadImagePath"
                    />
                  </div>

                </v-col>
              </v-row>

              <v-row v-if="imageDialogFormat === 'withAspectRatio' && originImage.loaded" justify="center">
                <v-col cols="12" sm="6" align-self="center">
                  <v-radio-group v-model="selectedAspect" row>
                    <v-radio
                        value="1x1"
                        class="pr-3"
                    >
                      <template v-slot:label>
                        <v-badge color="green" v-if="confirmCrop['1x1'].confirm">
                          <template v-slot:badge>
                            <v-icon>mdi-check</v-icon>
                          </template>
                          1x1
                        </v-badge>
                        <span v-else>1x1</span>
                      </template>
                    </v-radio>
                    <v-radio
                        value="2x1"
                        class="pr-3"
                    >
                      <template v-slot:label>
                        <v-badge color="green" v-if="confirmCrop['2x1'].confirm">
                          <template v-slot:badge>
                            <v-icon>mdi-check</v-icon>
                          </template>
                          2x1
                        </v-badge>
                        <span v-else>2x1</span>
                      </template>
                    </v-radio>
                    <v-radio
                        value="3x2"
                        class="pr-3"
                    >
                      <template v-slot:label>
                        <v-badge color="green" v-if="confirmCrop['3x2'].confirm">
                          <template v-slot:badge>
                            <v-icon>mdi-check</v-icon>
                          </template>
                          3x2
                        </v-badge>
                        <span v-else>3x2</span>
                      </template>
                    </v-radio>
                    <v-radio
                        value="4x3"
                        class="pr-3"
                    >
                      <template v-slot:label>
                        <v-badge color="green" v-if="confirmCrop['4x3'].confirm">
                          <template v-slot:badge>
                            <v-icon>mdi-check</v-icon>
                          </template>
                          4x3
                        </v-badge>
                        <span v-else>4x3</span>
                      </template>
                    </v-radio>
                    <v-radio
                        value="16x9"
                        class="pr-3"
                    >
                      <template v-slot:label>
                        <v-badge color="green" v-if="confirmCrop['16x9'].confirm">
                          <template v-slot:badge>
                            <v-icon>mdi-check</v-icon>
                          </template>
                          16x9
                        </v-badge>
                        <span v-else>16x9</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>

              </v-row>
              <v-row justify="center" v-if="imageDialogFormat === 'withAspectRatio' && originImage.loaded">
                <v-col cols="12" sm="1" align-self="center" class="pa-0 ma-0">
                  <v-checkbox label="Confirm"
                              hide-details
                              v-model="confirmCrop[selectedAspect].confirm"
                              @change="onChangeConfirm">
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>

          </v-form>
        </v-card-text>

        <v-container v-if="imageDialogFormat === 'withAspectRatio' && originImage.loaded"
                      class="d-flex">
          <v-row >

            <v-col cols="12" sm="6" class="text-center flex-column align-center justify-center">
              <div>
                <div
                    v-if="originImage.loaded && !isDisableDownloadImageFromUrl() && downloadImageFromUrl !== null && downloadImageFromUrl.length>0"
                >
                  <!--          :style="{border: '1px solid rgb(0 0 0 / 11%)'}" -->
                  <v-img
                      contain
                      :width="originImage.width"
                      :height="originImage.height"
                      :lazy-src="downloadImageFromUrl"
                      :src="downloadImageFromUrl"
                  >
                    <vue-draggable-resizable
                        v-if="isReadyPreview"
                        z-index="100"
                        :parent="true"
                        @dragging="onDrag"
                        :w="cropWindowWidth"
                        :h="cropWindowHeight"
                        :resizable="false"
                        :draggable="!confirmCrop[selectedAspect].confirm"
                        :x="posX"
                        :y="posY"
                    >
                    </vue-draggable-resizable>
                  </v-img>

                </div>


                <div

                    v-if="originImage.loaded && !isDisableUploadImage()"
                    :style="{width:originImage.width + 'px', height: originImage.height + 'px'}">
                  <vue-draggable-resizable
                      v-if="isReadyPreview"
                      :z="1000"
                      :parent="true"
                      @dragging="onDrag"
                      :w="cropWindowWidth"
                      :h="cropWindowHeight"
                      :resizable="false"
                      :draggable="!confirmCrop[selectedAspect].confirm"
                      :x="posX"
                      :y="posY"
                  >
                  </vue-draggable-resizable>
                  <video-player :options="videoOptions">

                  </video-player>
                </div>
              </div>

            </v-col>

            <v-col cols="12" sm="6" :style="{width: '100%', height: '100%'}">
              <div >
                <vue-draggable-resizable
                    :parent="true"
                    v-if="isReadyPreview && imageDialogFormat === 'withAspectRatio' && originImage.loaded"
                    :z="999"
                    :w="previewWindowWidth"
                    :h="previewWindowHeight"
                    style="{overflow: hidden}"
                    :resizable="false"
                >
                  <img
                      v-if="isDisableUploadImage ()"
                      class="py-0 my-0"
                      :height="originImage.height"
                      :width="originImage.width"
                      :style="{'object-fit': 'cover', border: '1px solid rgb(0 0 0 / 11%)', 'object-position': left*-1+'px '+top*-1+'px', position: 'absolute'}"
                      :lazy-src="downloadImageFromUrl"
                      :src="downloadImageFromUrl"
                  >

                  <div v-else :style="{height: (previewWindowHeight) + 'px', width: (previewWindowWidth) + 'px' }">
                    <video-player
                        :options="{...videoOptions}"

                        :style="{'object-fit': 'cover', border: '1px solid rgb(0 0 0 / 11%)', 'object-position': left*-1+'px '+top*-1+'px', position: 'absolute',

                          height: (previewWindowHeight) + 'px', width: (previewWindowWidth) + 'px' }"
                    ></video-player>
                  </div>


                  <!--          <img-->
                  <!--              v-else-->
                  <!--              class="py-0 my-0"-->
                  <!--              :height="originImage.height/previewScale"-->
                  <!--              :width="originImage.width/previewScale"-->
                  <!--              :style="{'object-fit': 'cover', border: '1px solid rgb(0 0 0 / 11%)', 'object-position': left/previewScale*-1+'px '+top/previewScale*-1+'px', position: 'absolute'}"-->
                  <!--              :src="uploadImagePath"-->
                  <!--          >-->
                </vue-draggable-resizable>
              </div>

            </v-col>
          </v-row>

        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--        <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>-->
          <!--        <v-btn color="primary"-->
          <!--               :disabled="!confirmSelectSquareImage || (!formAddImage || (waitAddNewImage || !(isDisableDownloadImageFromUrl() || isDisableUploadImage())))  "-->
          <!--               :loading="waitAddNewImage"-->
          <!--               @click="onUploadImage">-->
          <!--          <v-icon>mdi-content-save</v-icon>-->
          <!--          Add-->
          <!--        </v-btn>-->
          <!--        <v-spacer></v-spacer>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
te>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

import api from '@/utils/api'
import VideoPlayer from './VideoPlayer.vue'
import MediaInfoFactory from 'mediainfo.js'

import "video.js/dist/video-js.css"

export default {
  name: 'DialogAddNewVideo',
  components: {'vue-draggable-resizable': VueDraggableResizable, VideoPlayer},
  props: {
    value: {
      default: false,
    },
    type: {
      default: 'video',
    },
    tags: {
      default: []
    },
    selectedImage: {
      default: {
        tag1: '',
        tag2: '',
        tag3: ''
      }
    }
  },
  data() {
    return {
      dialog: this.value,
      isVisibleValidationErrorDialog: false,
      errorMessage: '',
      formAddImage: false,
      downloadImageFromUrl: '',
      uploadImageFile: null,
      uploadImagePath: null,

      imgMinWidthOld: 200,
      imgMinHeightOld: 200,

      imgGifMinWidthOld: 480,
      imgGifMinHeightOld: 270,

      imgMinWidth: 600,
      imgMinHeight: 382,

      originImage: {
        ext: '',
        size: 0,
        loaded: false,
        height: 0,
        width: 0
        // aspectRatio: 0
      },
      tag1: '',
      tag2: '',
      tag3: '',

      waitAddNewImage: false,

      dialogEditImageTags: false,

      isDisabledTags: false,
      isDisabledTags_2: false,

      thisIsDisabledTags: false,
      thisIsDisabledTags_2: false,

      minWidth: 328,
      minHeight: 328,

      maxWidth: 328 * 2,
      maxHeight: 328 * 2,

      cropWindowWidth: 0,
      cropWindowHeight: 0,

      previewWindowWidth: 328,
      previewWindowHeight: 328,

      posX: 0,
      posY: 0,

      left: 0,
      top: 0,

      isReadyPreview: false,
      previewScale: 2,

      previewImageX: 0,
      previewImageY: 0,

      // imageDialogFormat: 'old',
      imageDialogFormat: null,
      maxGifSize: 1024 * 1024 * 10,
      maxImageSize: 1024 * 1024 * 5,

      selectedAspect: '1x1',

      confirmCrop: {
        '1x1': {
          confirm: false,
          aspect: 1 / 1,
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        },
        '2x1': {
          confirm: false,
          aspect: 2 / 1,
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        },
        '3x2': {
          confirm: false,
          aspect: 3 / 2,
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        },
        '4x3': {
          confirm: false,
          aspect: 4 / 3,
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        },
        '16x9': {
          confirm: false,
          aspect: 16 / 9,
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        },

      },

      videoOptions: {
        autoplay: true,
        controls: false,
        sources: [
          {
            src: '',
            type: 'video/mp4'
          }
        ],
        loop: true
      },

      videoOptionsPrev: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: '',
            type: 'video/mp4'
          }
        ],
        loop: true
      },

      mediaInfoStr: ''
    }
  },

  mounted() {
    this.imageDialogFormat = 'withAspectRatio'

    // console.log('DialogAddNewVideo', this.type)
    // if (this.$store.getters['user/getUserName'] === 'denis' ||
    //     this.$store.getters['user/getUserName'] === 'miro') {
    //   this.imageDialogFormat = 'withAspectRatio'
    // } else {
    //   this.imageDialogFormat = 'old'
    // }
  },

  methods: {
    isAllowAddImage() {
      if (this.imageDialogFormat === 'withAspectRatio') {
        if (!Object.entries(this.confirmCrop).every(([, {confirm}]) => confirm)) {
          return true
        }
      }
      return (!this.formAddImage ||
          (this.waitAddNewImage ||
              !(this.isDisableDownloadImageFromUrl() || this.isDisableUploadImage())))
    },

    async selectedUploadImage() {
      this.originImage.loaded = false

      this.resetForm()

      if (this.uploadImageFile) {

        this.originImage.size = this.uploadImageFile.size


        const getSize = () => this.uploadImageFile.size
        const readChunk = (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = (event) => {
                if (event.target.error) {
                  reject(event.target.error)
                }
                resolve(new Uint8Array(event.target.result))
                // console.log(this.uploadImageFile)
                this.videoOptions.sources[0].src = URL.createObjectURL(this.uploadImageFile)

              }
              reader.readAsArrayBuffer(this.uploadImageFile.slice(offset, offset + chunkSize));
            })

        try {
          const mediainfo = await MediaInfoFactory({format: 'object'})

          mediainfo.analyzeData(getSize, readChunk)
              .then((result) => {
                // this.$refs.ta.value = JSON.stringify(result, null, 2)
                this.mediaInfoStr = JSON.stringify(result.media, null, 2)
                console.log('mediainfo.analyzeData', result)
                console.log('mediainfo.analyzeData Media', result.media.track)
                const v = result.media.track.find(track => track['@type'] === 'Video')
                console.log('mediainfo.analyzeData Media VVV', v)
                if (v) {

                  // this.originImage.ext = this.uploadImageFile.type.split('/')[1]
                  this.originImage.width = v.Sampled_Width
                  this.originImage.height = v.Sampled_Height
                  this.originImage.aspectRatio = v.Stored_Width / v.Stored_Height

                  this.originImage.loaded = true



                  this.$nextTick(() => {
                    this.calcPreviewWindowProps()
                  })

                  this.$refs.formAddImage.validate()
                }

                // const urlFile = URL.createObjectURL(this.uploadImageFile)
                // console.log(urlFile, this.uploadImageFile)

              })
              .catch((error) => {
                console.error(error);
              })

        } catch (e) {
          console.error(e)
        }


        // let reader = new FileReader()
        //
        // reader.readAsDataURL(this.uploadImageFile)
        // reader.onload = evt => {
        //   let img = new Image()
        //   img.onload = () => {
        //     this.originImage.loaded = true
        //     this.originImage.ext = this.uploadImageFile.type.split('/')[1]
        //     this.originImage.width = img.width
        //     this.originImage.height = img.height
        //     this.originImage.size = this.uploadImageFile.size
        //     this.originImage.aspectRatio = img.width / img.height
        //
        //     this.calcPreviewWindowProps()
        //
        //     // console.log('originImage', JSON.stringify(img), img, )
        //
        //     // this.$refs.formAddImage.validate()
        //   }
        //   img.src = evt.target.result
        //
        //   this.uploadImagePath = URL.createObjectURL(this.uploadImageFile)
        //
        // }
        //
        // reader.onerror = evt => {
        //   console.error(evt)
        // }

      } else {
        this.uploadImagePath = null
      }

    },

    checkDownloadImage(value) {
      if (/ +/.test(value)) {
        return 'Delete blank symbols'
      }
      if (/^https?:\/\/.+/.test(value)) {
        if (this.isDisableDownloadImageFromUrl()) {
          return true
        }

        if (this.originImage.loaded) {
          let imgMinWidth
          let imgMinHeight

          if (this.imageDialogFormat === 'withAspectRatio') {
            imgMinWidth = this.imgMinWidth
            imgMinHeight = this.imgMinHeight
          } else {
            if (this.$route.params.section === 'AdNow') {
              imgMinWidth = 200
              imgMinHeight = 200

              if (this.originImage.width < imgMinWidth) {
                return 'Small Width. Need >= ' + imgMinWidth
              }

              if (this.originImage.height < imgMinHeight) {
                return 'Small Height. Need >= ' + imgMinHeight
              }

              return true
            }

            imgMinWidth = this.imgMinWidthOld
            imgMinHeight = this.imgMinHeightOld
          }

          if (this.originImage.width < imgMinWidth) {
            return 'Small Width. Need >= ' + imgMinWidth
          }

          if (this.originImage.height < imgMinHeight) {
            return 'Small Height. Need >= ' + imgMinHeight
          }

          if (this.originImage.ext === 'gif') {
            if (this.originImage.size > this.maxGifSize) {
              return 'Very big image, Need a smaller size'
            }
          } else if (this.originImage.size > this.maxImageSize) {
            return 'Very big image, Need a smaller size'
          }
          return true
        } else {
          return false
        }
      } else {
        return 'Link must be: https:// or http://'
      }
    },

    checkUploadImage() {
      if (this.isDisableUploadImage()) {
        return true
      }

      if (this.originImage.loaded) {
        let imgMinWidth
        let imgMinHeight

        if (this.imageDialogFormat === 'withAspectRatio') {
          if (this.originImage.ext === 'gif') {
            if (this.$route.params.section === 'AdNow') {
              imgMinWidth = 200
              imgMinHeight = 200
            } else {
              imgMinWidth = this.imgGifMinWidthOld
              imgMinHeight = this.imgGifMinHeightOld
            }
          } else {
            imgMinWidth = this.imgMinWidth
            imgMinHeight = this.imgMinHeight
          }
        } else {
          if (this.originImage.ext === 'gif') {
            if (this.$route.params.section === 'AdNow') {
              imgMinWidth = 200
              imgMinHeight = 200
            } else {
              imgMinWidth = this.imgGifMinWidthOld
              imgMinHeight = this.imgGifMinHeightOld
            }
          } else {
            if (this.$route.params.section === 'AdNow') {
              imgMinWidth = 200
              imgMinHeight = 200
            } else {
              imgMinWidth = this.imgMinWidthOld
              imgMinHeight = this.imgMinHeightOld
            }
          }
        }

        if (this.originImage.width < imgMinWidth) {
          return 'Small Width. Need >= ' + imgMinWidth
        }

        if (this.originImage.height < imgMinHeight) {
          return 'Small Height. Need >= ' + imgMinHeight
        }

        if (this.originImage.ext === 'gif') {
          if (this.originImage.size > this.maxGifSize) {
            return 'Very big image, Need a smaller size'
          }
        } else if (this.originImage.size > this.maxImageSize) {
          return 'Very big image, Need a smaller size'
        }
        return true
      } else {
        return false
      }
    },

    async onAddImage() {

      this.waitAddNewImage = true

      const tags = {}

      if (typeof this.tag1 === 'string' && this.tag1.length > 0) {
        tags.tag1 = this.tag1
      }
      if (typeof this.tag2 === 'string' && this.tag2.length > 0) {
        tags.tag2 = this.tag2
      }
      if (typeof this.tag3 === 'string' && this.tag3.length > 0) {
        tags.tag3 = this.tag3
      }

      // const type = this.$route.params.section === 'Geniee' ? 'banner' : 'native'

      if (this.uploadImageFile) {
        try {
          let res
          if (this.imageDialogFormat === 'withAspectRatio') {
            res = await api.dspUploadImg(this.uploadImageFile, JSON.stringify(tags), JSON.stringify(this.confirmCrop), this.type)
          } else {
            res = await api.dspUploadImg(this.uploadImageFile, JSON.stringify(tags), null, this.type)
          }

          if (!res.data.error) {
            this.uploadNewImage = true
            this.$emit('new-image', {id: res.data.id, url: res.data.url})
            this.waitAddNewImage = false
            this.dialog = false
          } else {
            this.errorMessage = res.data.message
            this.isVisibleValidationErrorDialog = true
            this.waitAddNewImage = false
          }
        } catch (e) {
          console.error('openChooseImageDialog', e)
          this.waitAddNewImage = false
        }
      } else if (this.downloadImageFromUrl) {

        try {
          let res

          if (this.imageDialogFormat === 'withAspectRatio') {
            res = await api.dspDownloadImg({
              tags,
              url: this.downloadImageFromUrl,
              crop: JSON.parse(JSON.stringify(this.confirmCrop)),
              type: this.type
            })
          } else {
            res = await api.dspDownloadImg({
              tags,
              url: this.downloadImageFromUrl,
              type: this.type
            })
          }

          if (!res.data.error) {
            this.selectedImage = {id: res.data.id, url: res.data.url}
            this.uploadNewImage = true
            // this.getImagesTags()
            // await this.getImagesList()
            this.$emit('new-image', {id: res.data.id, url: res.data.url})
            this.waitAddNewImage = false
            this.dialog = false
          } else {
            this.errorMessage = res.data.message
            this.isVisibleValidationErrorDialog = true
            this.waitAddNewImage = false
          }
        } catch (e) {
          console.error('openChooseImageDialog', e)
          this.waitAddNewImage = false
        }
      }

    },

    isDisableDownloadImageFromUrl() {
      if (typeof this.uploadImageFile === 'string' && this.uploadImageFile.length > 0) {
        return true
      }
      if (this.uploadImageFile === null) {
        return false
      }
      return true
    },

    isDisableUploadImage() {
      if (typeof this.downloadImageFromUrl === 'string' && this.downloadImageFromUrl.length > 0) {
        return true
      }
      if (this.downloadImageFromUrl === null) {
        return false
      }
      return false
    },

    onDrag(x, y) {
      if (this.confirmCrop[this.selectedAspect].confirm) {
        return
      }
      if (x < 0 || y < 0) {
        return
      }
      this.confirmCrop[this.selectedAspect].left = this.left = x
      this.confirmCrop[this.selectedAspect].top = this.top = y
    },

    calcPreviewWindowProps() {
      let width
      let height

      if (this.originImage.loaded) {
        this.isReadyPreview = false
        const inputImageAspectRatio = this.originImage.width / this.originImage.height;
        console.log(`inputImageAspectRatio: ${inputImageAspectRatio}`)
        console.log(`originImage - w: ${this.originImage.width} h:${this.originImage.height}`)

        this.$nextTick(() => {
          if (this.originImage.aspectRatio > this.confirmCrop[this.selectedAspect].aspect) {
            width = this.originImage.height * this.confirmCrop[this.selectedAspect].aspect
            height = this.originImage.height
          } else {
            width = this.originImage.width
            height = this.originImage.width / this.confirmCrop[this.selectedAspect].aspect
          }

          this.cropWindowWidth = Math.trunc(width)
          this.cropWindowHeight = Math.trunc(height)

          this.previewWindowWidth = Math.trunc(width)
          this.previewWindowHeight = Math.trunc(height)

          console.log(`Prev width: ${width} height: ${height}`)

          this.posX = this.confirmCrop[this.selectedAspect].left
          this.posY = this.confirmCrop[this.selectedAspect].top

          this.left = this.confirmCrop[this.selectedAspect].left
          this.top = this.confirmCrop[this.selectedAspect].top

          console.log('posX', this.posX)
          console.log('posY', this.posY)

          this.isReadyPreview = true
          this.$refs.formAddImage.validate()
        })

      } else {
        this.isReadyPreview = false
        this.$refs.formAddImage.validate()
      }

    },

    onChangeConfirm() {
      if (this.confirmCrop[this.selectedAspect].confirm) {

        // console.log('onChangeConfirm 1', this.selectedAspect, this.left, this.top, this.cropWindowWidth, this.cropWindowHeight)

        this.$nextTick(() => {
          // console.log('onChangeConfirm 2', this.selectedAspect, this.left, this.top, this.cropWindowWidth, this.cropWindowHeight)

          this.confirmCrop[this.selectedAspect].left = this.left
          this.confirmCrop[this.selectedAspect].top = this.top
          this.confirmCrop[this.selectedAspect].width = this.cropWindowWidth
          this.confirmCrop[this.selectedAspect].height = this.cropWindowHeight
        })

        this.$nextTick(() => {
          if (this.selectedAspect === '1x1') {
            this.selectedAspect = '2x1'
          } else if (this.selectedAspect === '2x1') {
            this.selectedAspect = '3x2'
          } else if (this.selectedAspect === '3x2') {
            this.selectedAspect = '4x3'
          } else if (this.selectedAspect === '4x3') {
            this.selectedAspect = '16x9'
          }
        })
      }
    },
    resetForm() {
      this.selectedAspect = '1x1'
      Object.entries(this.confirmCrop).forEach(([, c]) => c.confirm = false)
      // this.calcPreviewWindowProps()
    }
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$emit('input', false)
      }
    },

    downloadImageFromUrl(value) {
      this.originImage.loaded = false
      if (value) {
        let img = new Image()
        img.onload = async () => {
          const fileImg = await fetch(value).then(r => r.blob())
          this.originImage.ext = fileImg.type.split('/')[1]
          this.originImage.size = fileImg.size
          this.originImage.width = img.width
          this.originImage.height = img.height
          this.originImage.aspectRatio = img.width / img.height
          this.originImage.loaded = true
          this.calcPreviewWindowProps()
        }
        img.src = value
      }
    },

    selectedAspect() {
      this.calcPreviewWindowProps()
    },

    imageDialogFormat(value) {
      if (value === 'withAspectRatio') {
        this.tag3 = 'withAspectRatio'
      } else {
        this.tag3 = null
      }
      this.$refs.formAddImage.validate()
    }

  }

}
</script>

<style scoped>

</style>