<template>
  <v-container fluid>
    <v-dialog v-model="isVisibleErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">API error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="4">
        <v-row>
          <v-col justify="center"
                 align="center"
                 class="subtitle-1">
            Places
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-toolbar color="elevation-0" dense>
              <v-col cols="12">
                <v-toolbar color="elevation-0" dense>

                  <v-dialog
                      v-model="dialogAddNewPlaces"
                      width="563"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn label="Add" hide-details
                             class="ml-0 mr-5" fab elevation="0" x-small dark color="primary"
                             v-bind="attrs"
                             v-on="on"
                             dialogAddNewPlaces=false
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>

                      <!--                    <v-btn-->
                      <!--                        color="red lighten-2"-->
                      <!--                        dark-->
                      <!--                        v-bind="attrs"-->
                      <!--                        v-on="on"-->
                      <!--                    >-->
                      <!--                      Click Me-->
                      <!--                    </v-btn>-->
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        <v-row>
                          <v-col>
                            <v-text-field
                                label="Place or places, separated by commas: (id_1,id_2,) or blank: (id_1 id_2 )."
                                @input="onAddNewInputPlaces"
                                :error="addNewInputPlacesError"
                                :error-messages="addNewInputPlacesErrorMessages"
                                autofocus
                            ></v-text-field>
                            <v-text-field label="Percent, %" v-model="defaultTargetValue"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col>
                            <v-btn small @click.s.stop="defaultTargetValue = 10"
                                   :color="defaultTargetValue === 10 ? 'primary':'' ">10
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 20"
                                   :color="defaultTargetValue === 20 ? 'primary':'' ">20
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 30"
                                   :color="defaultTargetValue === 30 ? 'primary':'' ">30
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 40"
                                   :color="defaultTargetValue === 40 ? 'primary':'' ">40
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 50"
                                   :color="defaultTargetValue === 50 ? 'primary':'' ">50
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 60"
                                   :color="defaultTargetValue === 60 ? 'primary':'' ">60
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 70"
                                   :color="defaultTargetValue === 70 ? 'primary':'' ">70
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 80"
                                   :color="defaultTargetValue === 80 ? 'primary':'' ">80
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 90"
                                   :color="defaultTargetValue === 90 ? 'primary':'' ">90
                            </v-btn>
                            <v-btn small @click.s.stop="defaultTargetValue = 100"
                                   :color="defaultTargetValue === 100 ? 'primary':'' ">100
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row class="pt-2">
                          <v-col justify="center"
                                 align="center">
                            <v-btn small color="primary"
                                   :disabled="addNewInputPlacesError || addNewResultPlaces.length === 0 || addNewGroupsSelection.length === 0"
                                   @click="onSaveInDialogAddNewPlaces()">
                              Save
                              <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                            <v-btn color="primary" text
                                   @click="dialogAddNewPlaces = false">
                              Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-title>

                      <CTreeview
                          v-model="addNewGroupsSelection"
                          :items="addNewGroups"
                          :active.sync="addNewGroupsActive"
                          selectable
                          dense
                          transition
                          open-all
                      ></CTreeview>

                      <!--                    <v-card-text>-->
                      <!--                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut-->
                      <!--                      labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris-->
                      <!--                      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit-->
                      <!--                      esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt-->
                      <!--                      in culpa qui officia deserunt mollit anim id est laborum.-->
                      <!--                    </v-card-text>-->

                      <!--                    <v-divider></v-divider>-->

                    </v-card>
                  </v-dialog>
<!--                  <v-spacer></v-spacer>-->
                  <v-switch label="Multi" hide-details
                            v-model="targetsSelectable">
                  </v-switch>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="!targetsSelectable || targetsSelection.length === 0" icon>
                        <v-icon>mdi-file-document-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Crete new teaser and add this Sources</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="teaserIdForCopy"
                      dense
                      hide-details
                      label="Id"
                      type="string"
                      clearable
                      :disabled="!targetsSelectable || targetsSelection.length === 0"
                  ></v-text-field>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="!targetsSelectable || targetsSelection.length === 0 || !teaserIdForCopy" icon>
                        <v-icon>mdi-file-document-arrow-right-outline</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </template>
                    <span>Copy sources to exists teaser</span>
                  </v-tooltip>



                </v-toolbar>
              </v-col>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4">
        <v-row>
          <v-col justify="center"
                 align="center"
                 class="subtitle-1">
            Active targets
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-toolbar color="elevation-0" dense>
              <v-switch label="Multi" hide-details
                        v-model="targetsUsedSelectable"
                        :disabled="isDisabledPlaces()"></v-switch>

              <v-spacer></v-spacer>
              <v-autocomplete
                  dense
                  v-if="targetsUsedSelectable"
                  class="pl-5"
                  :items="groupsItems"
                  v-model="defaultTargetValue"
                  type="number"
                  hide-details
                  solo
              >
              </v-autocomplete>


              <v-dialog
                  v-if="targetsUsedSelectable"
                  width="563"
                  v-model="dialogTargetsConfirmUpdate"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small @click.stop="onClickAppendUpdateUsed"
                         class="mr-5"
                         v-bind="attrs"
                         v-on="on"
                         :disabled="targetsUsedSelection.length === 0"
                  >
                    <v-icon>mdi-content-save-edit-outline</v-icon>
                  </v-btn>

                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-row>
                      <v-col justify="center" align="center">
                        <span>Update target, set: {{ defaultTargetValue }} % ?</span>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text class="text-h5 grey lighten-2">
                    <v-row>
                      <v-col justify="center" align="center">
                        <v-btn color="primary" class="mr-3" @click="onTargetMassUpdateValue">Yes
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn color="primary" text
                               @click="dialogTargetsConfirmUpdate = false">Cancel
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-dialog>


              <!--              <v-tooltip bottom v-if="targetsUsedSelectable">-->
              <!--                <template v-slot:activator="{ on, attrs }">-->
              <!--                  <v-btn icon small @click.stop="onClickAppendUpdateUsed"-->
              <!--                         class="mr-5"-->
              <!--                         v-bind="attrs"-->
              <!--                         v-on="on"-->
              <!--                  >-->
              <!--                    <v-icon>mdi-content-save-edit-outline</v-icon>-->
              <!--                  </v-btn>-->
              <!--                </template>-->
              <!--                <span>Update target, set: {{ defaultTargetValue }} %</span>-->
              <!--              </v-tooltip>-->

              <v-spacer></v-spacer>
              <v-dialog
                  v-if="targetsUsedSelectable"
                  width="563"
                  v-model="dialogConfirmDeleteTarget"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-if="targetsUsedSelectable" @click.stop=""
                         :disabled="targetsUsedSelection.length === 0"
                         v-bind="attrs"
                         v-on="on"
                  >
                    <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>

                  <!--                  <v-btn icon small @click.stop="onClickAppendUpdateUsed"-->
                  <!--                         class="mr-5"-->
                  <!--                         v-bind="attrs"-->
                  <!--                         v-on="on"-->
                  <!--                         :disabled="targetsUsedSelection.length === 0"-->
                  <!--                  >-->
                  <!--                    <v-icon>mdi-content-save-edit-outline</v-icon>-->
                  <!--                  </v-btn>-->

                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-row>
                      <v-col justify="center" align="center">
                        <span>Delete targets ?</span>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text class="text-h5 grey lighten-2">
                    <v-row>
                      <v-col justify="center" align="center">
                        <v-btn color="primary" class="mr-3"
                               @click="deleteTargets">Yes
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn color="primary" text
                               @click="dialogConfirmDeleteTarget = false"
                        >Cancel
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-dialog>


            </v-toolbar>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4">
        <v-row>
          <v-col justify="center"
                 align="center"
                 class="subtitle-1">
            Available targets
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-toolbar color="elevation-0" dense>
              <v-btn icon small v-if="groupsSelectable" @click.stop="onGroupsSelectableSave"
                     :disabled="groupsSelection.length === 0">
                <v-icon>mdi-arrow-left-thick</v-icon>
              </v-btn>
              <v-switch label="Multi" hide-details
                        v-model="groupsSelectable"
                        :disabled="groups === undefined"
              ></v-switch>
              <v-spacer></v-spacer>
              <v-autocomplete
                  dense
                  :disabled="groups === undefined"
                  class="pl-5"
                  :items="groupsItems"
                  v-model="defaultTargetValue" type="number"
                  hide-details
                  solo
              ></v-autocomplete>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12" sm="4">
        <v-card flat>
          <v-autocomplete
              :items="targets"
              item-text="name"
              label="Search"
              prepend-icon="mdi-magnify"
              hide-details
              clearable
              type="string"
              :disabled="targetsSelectable"
              v-model="targetsSearch"
              autofocus

          >
          </v-autocomplete>
          <v-treeview
              v-model="targetsSelection"
              dense
              :active.sync="targetsActive"
              :items="targets"
              :search="targetsSearch"
              :open.sync="targetsOpen"
              :selectable="targetsSelectable"
              @update:active="onUpdateActiveTargets"
              :activatable="!targetsSelectable"
              transition
              open-all
              :filter="filter"
          >
          </v-treeview>
        </v-card>

        <!--        <div-->
        <!--            v-for="node in targetsSelection"-->
        <!--            :key="node.id"-->
        <!--        >-->
        <!--          {{ node.name }}-->
        <!--        </div>-->
        <!--        <v-divider-->
        <!--            vertical-->
        <!--        ></v-divider>-->
      </v-col>

      <v-col cols="12" sm="4">
        <v-card flat>
          <CTreeview
              v-model="targetsUsedSelection"
              :active.sync="targetsUsedActive"
              :items="targetsUsed"
              :open.sync="targetsUsedOpen"
              :selectable="targetsUsedSelectable"
              dense
              transition
              open-all
              hoverable
          >
            <template v-slot:label="{ item }">
              <v-row>

                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col>
                      {{ item.name }}
                    </v-col>

                  </v-row>
                  <v-row class="pt-0 mt-0" v-if="item.id !== item.name">
                    <v-col class="pt-0 mt-0">
                      <v-progress-linear :value="item.target"></v-progress-linear>
                    </v-col>
                  </v-row>
                  <!--                  <div></div>-->
                  <!--                  <div class="align-self-center">-->
                  <!--                  <div class="black">-->

                  <!--                  </div>-->
                </v-col>
                <v-col cols="12" sm="4" v-if="'target' in item">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <!--                      <div class="align-self-center">-->
                      <v-btn
                          x-small
                          v-bind="attrs"
                          v-on="{ ...menu }"
                          text
                          :disabled="targetsUsedSelectable"
                      >
                        {{ item.target + ' %' }}
                      </v-btn>
                      <!--                      </div>-->
                    </template>
                    <v-card min-width="400px" min-height="100" flat>

                      <v-card-text>
                        <v-row>
                          <v-col>
                            How % set up for <span class="primary--text">"{{ item.name }}"</span>
                          </v-col>
                        </v-row>
                        <v-row class="pt-2">
                          <v-btn small @click="defaultTargetValue = 10;updateTarget(item)"
                                 :color="item.target > 0 && item.target <= 10 ? 'primary':'' ">10
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 20;updateTarget(item)"
                                 :color="item.target > 10 && item.target <= 20 ? 'primary':'' ">20
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 30;updateTarget(item)"
                                 :color="item.target > 20 && item.target <= 30 ? 'primary':'' ">30
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 40;updateTarget(item)"
                                 :color="item.target > 30 && item.target <= 40 ? 'primary':'' ">40
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 50;updateTarget(item)"
                                 :color="item.target > 40 && item.target <= 50 ? 'primary':'' ">50
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 60;updateTarget(item)"
                                 :color="item.target > 50 && item.target <= 60 ? 'primary':'' ">60
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 70;updateTarget(item)"
                                 :color="item.target > 60 && item.target <= 70 ? 'primary':'' ">70
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 80;updateTarget(item)"
                                 :color="item.target > 70 && item.target <= 80 ? 'primary':'' ">80
                          </v-btn>
                          <v-btn small @click.s="defaultTargetValue = 90;updateTarget(item)"
                                 :color="item.target > 80 && item.target <= 90 ? 'primary':'' ">90
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 100;updateTarget(item)"
                                 :color="item.target > 90 && item.target <= 100 ? 'primary':'' ">100
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 200;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 100 && item.target <= 200 ? 'primary':'' ">200
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 300;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 200 && item.target <= 300 ? 'primary':'' ">300
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 400;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 300 && item.target <= 400 ? 'primary':'' ">400
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 500;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 400 && item.target <= 500 ? 'primary':'' ">500
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 600;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 500 && item.target <= 600 ? 'primary':'' ">600
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 700;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 600 && item.target <= 700 ? 'primary':'' ">700
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 800;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 700 && item.target <= 800 ? 'primary':'' ">800
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 900;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 800 && item.target <= 900 ? 'primary':'' ">900
                          </v-btn>
                          <v-btn small @click="defaultTargetValue = 1000;updateTarget(item)"
                                 v-if="mode === 23"
                                 :color="item.target > 900 && item.target <= 1000 ? 'primary':'' ">1000
                          </v-btn>

                        </v-row>
                        <!--                          <v-row class="pt-3">-->
                        <!--                            <v-col class="d-flex justify-center">-->
                        <!--                              <v-btn small @click.s.stop="updateTarget(item)">-->
                        <!--                                <v-icon color="primary">mdi-arrow-left-thick</v-icon>-->
                        <!--                                ADD-->
                        <!--                              </v-btn>-->
                        <!--                            </v-col>-->
                        <!--                          </v-row>-->
                      </v-card-text>
                      <!--                      </v-card>-->
                      <!--                      <v-card-text class="pt-16">-->
                      <!--                        <v-slider-->
                      <!--                            @input="item.target = $event"-->
                      <!--                            :value="item.target"-->
                      <!--                            :label="item.name"-->
                      <!--                            @change="updateTarget(item)"-->
                      <!--                            min="1"-->
                      <!--                            max="100"-->
                      <!--                            thumb-label="always"-->
                      <!--                            hide-details-->
                      <!--                            append-icon="mdi-percent-outline"-->
                      <!--                        >-->
                      <!--                        </v-slider>-->
                      <!--                      </v-card-text>-->
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
            <template v-slot:append="{ item, hover }">
              <v-btn icon x-small v-if=" hover && !targetsUsedSelectable" @click.stop="deleteTarget(item)">
                <v-icon>mdi-arrow-right-thick</v-icon>
              </v-btn>
            </template>
          </CTreeview>

        </v-card>

      </v-col>
      <v-divider :style="{margin: '0 -1.5px'}" vertical></v-divider>
      <v-col cols="12" sm="4">

        <v-card v-if="groups !== undefined" flat>
          <CTreeview
              v-model="groupsSelection"
              :items="groups"
              :active.sync="groupsActive"
              :open.sync="groupsOpen"
              :selectable="groupsSelectable"
              dense
              transition
              open-all
          >
            <template v-slot:prepend="{ item, hover }">
              <v-menu
                  :nudge-left="150"
                  :ref="item.id.toString()"
                  offset-y v-if="!groupsSelectable"
                  close-on-click
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <template>
                    <v-btn icon x-small
                           @click.stop=""
                           v-bind="attrs"
                           v-on="{...menu }"
                    >
                      <v-icon v-if="hover">mdi-arrow-left-thick</v-icon>
                    </v-btn>
                  </template>
                </template>
                <v-card min-width="400px" min-height="130" flat>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        Add <span class="primary--text">"{{ item.name }}"</span>: <b
                          class="primary--text">{{ defaultTargetValue }}</b> %
                      </v-col>
                    </v-row>
                    <v-row class="pt-2">
                      <v-btn small @click.s.stop="defaultTargetValue = 10"
                             :color="defaultTargetValue === 10 ? 'primary':'' ">10
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 20"
                             :color="defaultTargetValue === 20 ? 'primary':'' ">20
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 30"
                             :color="defaultTargetValue === 30 ? 'primary':'' ">30
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 40"
                             :color="defaultTargetValue === 40 ? 'primary':'' ">40
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 50"
                             :color="defaultTargetValue === 50 ? 'primary':'' ">50
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 60"
                             :color="defaultTargetValue === 60 ? 'primary':'' ">60
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 70"
                             :color="defaultTargetValue === 70 ? 'primary':'' ">70
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 80"
                             :color="defaultTargetValue === 80 ? 'primary':'' ">80
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 90"
                             :color="defaultTargetValue === 90 ? 'primary':'' ">90
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 100"
                             :color="defaultTargetValue === 100 ? 'primary':'' ">100
                      </v-btn>

                      <v-btn small @click.s.stop="defaultTargetValue = 200"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 200 ? 'primary':'' ">200
                      </v-btn>

                      <v-btn small @click.s.stop="defaultTargetValue = 300"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 300 ? 'primary':'' ">300
                      </v-btn>

                      <v-btn small @click.s.stop="defaultTargetValue = 400"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 400 ? 'primary':'' ">400
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 500"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 500 ? 'primary':'' ">500
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 600"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 600 ? 'primary':'' ">600
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 700"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 700 ? 'primary':'' ">700
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 800"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 800 ? 'primary':'' ">800
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 900"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 900 ? 'primary':'' ">900
                      </v-btn>
                      <v-btn small @click.s.stop="defaultTargetValue = 1000"
                             v-if="mode === 23"
                             :color="defaultTargetValue === 1000 ? 'primary':'' ">1000
                      </v-btn>

                    </v-row>
                    <v-row class="pt-3">
                      <v-col class="d-flex justify-center">
                        <v-btn small @click.s.stop="confirmAddTarget(item)">
                          <v-icon color="primary">mdi-arrow-left-thick</v-icon>
                          ADD
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </CTreeview>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/utils/api'
import CTreeview from '@/components/mixins/CTreeview.js'

export default {
  name: 'TargetEditor',
  components: {CTreeview},
  props: {
    mode: {default: 0},
    teaserId: {default: ''},
    groupsRaw: {default: []}
  },
  data () {
    return {
      loading: false,
      targets: [],
      targetsSearch: '',
      targetsOpen: [],
      targetsActive: [],
      targetsActiveShadow: [],
      targetsSelectable: false,
      targetsSelection: [],

      targetsUsed: [],
      targetsUsedOpen: [],
      targetsUsedActive: [],
      targetsUsedSelectable: false,
      targetsUsedSelection: [],

      groups: undefined,
      groupsOpen: [],
      groupsActive: [],
      groupsSelectable: false,
      groupsSelection: [],

      defaultTargetValue: 50,

      dialogAddNewPlaces: false,

      addNewPlacesAvailableTargets: [],
      addNewGroups: [],
      addNewGroupsActive: [],
      addNewGroupsSelection: [],

      addNewInputPlaces: '',
      addNewInputPlacesError: false,
      addNewInputPlacesErrorMessages: '',

      addNewResultPlaces: [],

      isVisibleErrorDialog: false,
      errorMessage: '',

      dialogTargetsConfirmUpdate: false,
      dialogConfirmDeleteTarget: false,

      teaserIdForCopy: null
    }
  },
  mounted () {
    this.getTargetUa()
  },
  computed: {
    filter () {
      return (item, search, textKey) => item[textKey] === search
      // return (item, search, textKey) => {
      //   console.log('item, search, textKey', item, search, textKey)
      //   return -1
      // }
    },
    isAllowDeleteLastTarget: () => this.targetsUsed.length > 1,

    groupsItems () {
      if (this.mode === 23) {
        return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
      } else {
        return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
      }
    }

  },
  methods: {
    isDisabledPlaces () {
      if (this.targetsSelectable === true) {
        return false
      }
      if (this.groups === undefined) {
        return true
      }

      return false
    },

    async onUpdateActiveTargets () {
      this.$set(this, 'targetsUsedActive', [])
    },

    makeTreeForTarget (array, type = 'all') {
      // console.log('makeTreeForTarget array', array)
      // console.log('makeTreeForTarget array', array)
      // console.log('makeTreeForTarget this.targetUaListRaw', this.targetUaListRaw)
      const tree = this.groupsRaw.reduce((acc, item) => {

        if (
            type === 'all' ||
            (type === 'include' && array.some(x => x.group_id === item.group_id)) ||
            type === 'exclude' && !array.some(x => x.group_id === item.group_id)) {

          const target = type === 'include' ? array.find((a) => a.group_id === item.group_id).target : ''

          if (item.device_type === null) {
            item.device_type = 'Other'
          }
          const i = acc.findIndex(a => a.id === item.device_type)
          if (i === -1) {
            acc.push({
              name: item.device_type,
              id: item.device_type,
              origin: item,
              children: [{
                name: item.name,
                device_type: item.device_type,
                id: item.group_id,
                origin: item,
                children: [],
                target
              }]
            })
          } else {
            item.platform = item.platform === null ? 'Other' : item.platform
            acc[i].children.push({
              name: item.name,
              id: item.group_id,
              origin: item,
              target
            })

          }
        }
        return acc
      }, [])
      // console.log('makeTreeForTarget tree', tree)
      return tree
    },

    async getTargetUa () {

      this.loading = true

      const q = {
        networkName: this.$route.params.section,
        id: this.teaserId,
        // limit: this.itemsPerPageTargetUa,
        limit: 100000,
        page: 0,
      }

      try {
        const res = await api.dspTargetUa(q)
        const targets = res.data.targets.reduce((acc, t) => {
          const id = 'offer_id ' + t.offer_id + ' ' + t.uid
          const i = acc.findIndex(a => a.id === id)
          if (i === -1) {
            acc.push({
              id,
              name: t.uid.toString(),
              group: [t]
            })
          } else {
            acc[i].group.push(t)
          }
          return acc
        }, [])

        this.$set(this, 'targets', targets)

        if (!this.targetsSelectable && Array.isArray(this.targetsActive)) {
          if (this.targetsActive.length === 1) {
            const i = this.targets.findIndex(t => t.id === this.targetsActive[0].toString())
            if (i > -1) {
              this.targetsUsed = this.makeTreeForTarget(this.targets[i].group, 'include')
              this.groups = this.makeTreeForTarget(this.targets[i].group, 'exclude')
            } else {
              this.targetsUsed = undefined
              this.groups = undefined
            }
          } else {
            this.targetsUsed = undefined
            this.groups = undefined
          }
        } else if (!this.targetsSelectable) {
          this.targetsUsed = undefined
          this.groups = undefined
        }

      } catch (e) {
        console.error('getTargetUa', e)
      }

      // this.loading = false
    },

    onAddNewInputPlaces (value) {

      if (typeof value === 'string') {
        const places = value.split(/ |,/).filter((f) => f !== '')
        // console.log('onClickTargetUpdateValue', value, places)
        if (places.length === 0) {
          this.addNewInputPlacesError = true
          this.addNewInputPlacesErrorMessages = 'Please input place'
          this.$set(this, 'addNewResultPlaces', [])
        } else {

          const map = {}

          for (let i = 0; i < places.length; i++) {
            // check if object contains entry with this element as key
            if (map[places[i]]) {
              this.addNewInputPlacesErrorMessages = `Find duplicate: '${places[i]}' by number ${i + 1}`
              this.addNewInputPlacesError = true
              this.$set(this, 'addNewResultPlaces', [])
              return
            }
            map[places[i]] = true
          }

          this.addNewInputPlacesError = false
          this.addNewInputPlacesErrorMessages = ''
          this.$set(this, 'addNewResultPlaces', places)
        }
      } else {
        this.addNewInputPlacesError = true
        this.addNewInputPlacesErrorMessages = 'Please input place'
        this.$set(this, 'addNewResultPlaces', [])
      }
    },

    async onTargetMassUpdateValue () {

      let places
      let groups

      if (this.targetsSelectable) {
        places = this.targetsSelection.map(id => id.split(' ')[2])
        groups = this.targetsUsedSelection

      } else {
        if (this.targetsActive.length === 1) {
          places = [this.targets.find(t => t.id === this.targetsActive[0]).name]
          groups = this.targetsUsedSelection
        }
      }

      try {
        const res = await api.dspTargetUaUpdate({
          networkName: this.$route.params.section,
          teaserId: this.teaserId,
          places,
          groups,
          target: this.defaultTargetValue
        })
        if (!res.data.error) {
          // console.info('sendModerateAgain', res)
          this.isVisibleErrorDialog = false
          this.dialogAddNewPlaces = false
          await this.getTargetUa()
          this.dialogTargetsConfirmUpdate = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleErrorDialog = true
        }
      } catch (e) {
        this.errorMessage = e
        this.isVisibleErrorDialog = true
        console.error('dspTeaserNew', e)
      }
    },

    deleteTarget (value) {

      let groups = []

      if (value.name === 'All') {
        groups = value.children.map(r => r.children.map(c => c.id)).flat()
      } else {
        if (value.id === value.name) {
          groups = value.children.map(c => c.id)
        } else {
          groups = [value.id]
        }
      }

      let targets = []

      if (this.targetsSelectable) {
        targets = this.targetsSelection.map(id => id.split(' ')[2])
      } else {
        if (this.targetsActive.length === 1) {
          const t = this.targets.find(t => t.id === this.targetsActive[0])
          targets = [t.name]
        }
      }

      console.log('deleteTarget targets groups', targets, groups)
      if (targets.length > 0 && groups.length > 0) {
        this.deletePlaces(targets, groups)
      }
    },

    async deleteTargets () {
      let targets
      if (this.targetsSelectable) {
        targets = this.targetsSelection.map(id => id.split(' ')[2])
      } else {
        const t = this.targets.find(t => t.id === this.targetsActive[0])
        targets = [t.name]
      }

      // console.log('deleteTargets targets groups', targets, this.targetsUsedSelection)

      await this.deletePlaces(targets, this.targetsUsedSelection)
      this.dialogConfirmDeleteTarget = false
    },

    async updateTarget (value) {
      // console.log('updateTarget', value, defaultTargetValue)
      // let groups
      // if (value.id === value.name) {
      //   groups = value.children.map(c => c.id)
      // } else {
      //   groups = [value.id]
      // }
      const places = this.targets.find(t => t.id === this.targetsActive[0])
      // console.log('updateTarget', places, value.id, this.defaultTargetValue)
      try {
        const res = await api.dspTargetUaUpdate({
          networkName: this.$route.params.section,
          teaserId: this.teaserId,
          places: [places.name],
          groups: [value.id],
          target: this.defaultTargetValue
        })
        if (!res.data.error) {
          // console.info('sendModerateAgain', res)
          this.isVisibleErrorDialog = false
          this.dialogAddNewPlaces = false
          await this.getTargetUa()
        } else {
          this.errorMessage = res.data.message
          this.isVisibleErrorDialog = true
        }
      } catch (e) {
        this.errorMessage = e
        this.isVisibleErrorDialog = true
        console.error('dspTeaserNew', e)
      }
    },

    async confirmAddTarget (value) {
      this.$refs[value.id].save()

      let groups
      if (value.name === 'All') {
        groups = value.children.map(r => r.children.map(c => c.id)).flat()
      } else {
        if (value.id === value.name) {
          groups = value.children.map(c => c.id)
        } else {
          groups = [value.id]
        }
      }

      let targets
      if (this.targetsSelectable) {
        targets = this.targetsSelection.map(id => id.split(' ')[2])
      } else {
        if (this.targetsActive.length === 1) {
          const t = this.targets.find(t => t.id === this.targetsActive[0])
          targets = [t.name]
        }
      }

      // console.log('confirmAddTarget target', targets, groups)
      await this.addNewPlaces(targets, groups)

    },
    onClickAppendUpdateUsed () {
      console.log('onClickAppendUpdateUsed')
    },

    async onSaveInDialogAddNewPlaces () {
      await this.addNewPlaces(this.addNewResultPlaces, this.addNewGroupsSelection)
    },

    async onGroupsSelectableSave () {
      // console.log('onGroupsSelectableSave', this.groupsSelection)
      // let groups
      // if (value.id === value.name) {
      //   groups = value.children.map(c => c.id)
      // } else {
      //   groups = []
      // }
      const target = this.targets.find(t => t.id === this.targetsActive[0])
      await this.addNewPlaces([target.name], this.groupsSelection)
    },

    async addNewPlaces (places, groups) {
      try {
        const res = await api.dspTargetUaInsert({
          networkName: this.$route.params.section,
          teaserId: this.teaserId,
          places,
          groups,
          target: this.defaultTargetValue
        })
        if (!res.data.error) {
          // console.info('sendModerateAgain', res)
          this.isVisibleErrorDialog = false
          this.dialogAddNewPlaces = false
          await this.getTargetUa()
        } else {
          this.errorMessage = res.data.message
          this.isVisibleErrorDialog = true
        }
      } catch (e) {
        this.errorMessage = e
        this.isVisibleErrorDialog = true
        console.error('dspTeaserNew', e)
      }
    },

    async deletePlaces (places, groups) {
      // console.log('deletePlaces', places, groups)
      // return
      try {
        const res = await api.dspTargetUaDelete({
          networkName: this.$route.params.section,
          teaserId: this.teaserId,
          places,
          groups
        })
        if (!res.data.error) {
          // console.info('sendModerateAgain', res)
          this.isVisibleErrorDialog = false
          this.dialogAddNewPlaces = false
          await this.getTargetUa()
        } else {
          this.errorMessage = res.data.message
          this.isVisibleErrorDialog = true
        }
      } catch (e) {
        this.errorMessage = e
        this.isVisibleErrorDialog = true
        console.error('deletePlaces', e)
      }
    },

  },
  watch: {
    targetsActive (value) {

      if (!this.targetsSelectable)
        if (Array.isArray(value)) {
          if (value.length === 1) {
            const i = this.targets.findIndex(t => t.id === value[0].toString())
            this.targetsUsed = [{
              id: 'All',
              name: 'All',
              children: this.makeTreeForTarget(this.targets[i].group, 'include')
            }]
            this.groups = [{id: 'All', name: 'All', children: this.makeTreeForTarget(this.targets[i].group, 'exclude')}]
            this.targetsUsedOpen = ['All']
            this.groupsOpen = ['All']
          } else {
            this.$set(this, 'targetsUsedOpen', [])
            this.$set(this, 'groupsOpen', [])
            this.targetsUsed = undefined
            this.groups = undefined
          }
        } else {
          this.$set(this, 'targetsUsedOpen', [])
          this.$set(this, 'groupsOpen', [])
          this.targetsUsed = undefined
          this.groups = undefined
        }
    },
    dialogAddNewPlaces (value) {
      if (value === true) {
        this.$set(this, 'addNewGroups', [{id: 'All', name: 'All', children: this.makeTreeForTarget([], 'exclude')}])
      }
    },

    targetsSelectable (value, old) {
      if (old === false && value === true) {
        this.$set(this, 'targetsUsed', [{id: 'All', name: 'All', children: this.makeTreeForTarget([], 'exclude')}])
        this.$set(this, 'groups', [{id: 'All', name: 'All', children: this.makeTreeForTarget([], 'exclude')}])
        this.$set(this, 'targets', [{id: 'All', name: 'All', children: this.targets}])

        this.$set(this, 'targetsActiveShadow', this.targetsActive)
        this.$set(this, 'targetsActive', [])

      } else if (value === false && old === true) {
        this.$set(this, 'targetsUsed', [])
        this.$set(this, 'groups', [])
        this.$set(this, 'targets', this.targets[0].children)

        this.$set(this, 'targetsActive', this.targetsActiveShadow)
        this.$set(this, 'targetsActiveShadow', [])
      }
    }
    // targetsUsedSelection (value) {
    //   console.log('targetsUsedSelection', value)
    // },
    //
    // groupsSelection (value) {
    //   console.log('groupsSelection', value)
    // }
  }
}
</script>

<style scoped>

</style>